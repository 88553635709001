import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Game from '../components/game';
import LoadingDialog from '../components/loadingDialog';
import Table from '../components/table';
import About from '../components/modals/modalAbout';
import { useGameContext } from '../context/gameContext';
import { usePeer } from '../context/peerContext';
import { TURN_TIME, NO_TURN_TIME } from '../utils/statics';
import { getRoomUrl } from '../utils/navigation';

function GameScreen() {
  const [showAbout, setShowAbout] = useState(false);
  const [isReady, setIsReady] = useState();
  const { token, setToken, setHostToken, isConnected } = usePeer();
  const { room, color, unlimited } = useParams();
  const { selectColor, setTurnMax, myColor, noMyColor } = useGameContext();

  useEffect(() => {
    selectColor(color);
    setTurnMax(unlimited ? NO_TURN_TIME : TURN_TIME);
  }, [color, unlimited, selectColor, setTurnMax]);

  useEffect(() => {
    if (myColor && noMyColor) {
      setHostToken?.(`${room}`);
    }
  }, [myColor, noMyColor, room, setHostToken, setToken]);

  useEffect(() => {
    if ((isConnected || room === 'ia') && myColor && !isReady) {
      setIsReady(true);
    }
  }, [myColor, isConnected, isReady, room]);

  return myColor ? (
    <>
      {showAbout && <About />}
      {isReady ? (
        <Game>
          <Table />
        </Game>
      ) : (
        <LoadingDialog
          token={token}
          noMyColor={noMyColor}
          myColor={myColor}
          unlimited={unlimited}
        />
      )}
    </>
  ) : (
    'connecting'
  );
}

export default GameScreen;
