import {
  useCallback, useEffect, useRef, useState,
} from 'react';

const useRequestAnimationFrame = (callback) => {
  const callbackRef = useRef();
  const [playing, setPlaying] = useState();
  const playbackRef = useRef();
  const requestRef = useRef();

  callbackRef.current = callback;

  const stop = useCallback(() => {
    setPlaying(false);
    playbackRef.current = false;
    cancelAnimationFrame(requestRef.current);
  }, []);

  const animate = useCallback(
    (time) => {
      const now = new Date();

      callbackRef.current?.(stop, now);
      if (playbackRef.current) {
        requestRef.current = requestAnimationFrame(animate);
      }
    },
    [stop],
  );

  const play = useCallback(() => {
    setPlaying(true);
    playbackRef.current = true;
    requestRef.current = requestAnimationFrame(animate);
  }, [animate]);

  useEffect(() => {
    if (playing) play();

    return stop;
  }, [play, playing, stop]);

  return { play, stop };
};

export default useRequestAnimationFrame;
