import React from 'react';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas-lite';
import { useEffect } from 'react';
import { useCallback } from 'react';

const STATE_MACHINE = 'animations';
const STATE_MACHINE_TRIGGERS = {
  idle: 'idle',
  attack: 'attack',
  defense: 'defense',
  feint: 'feint',
  fullattack: 'fullattack',
  counterattack: 'counter',
};
const RANDOM_ANIMATION_INTERVAL = 4000;

export function Illustration() {
  const { rive, RiveComponent } = useRive({
    src: './animation.riv',
    stateMachines: STATE_MACHINE,
    autoplay: true,
  });

  const feintTrigger = useStateMachineInput(
    rive,
    STATE_MACHINE,
    STATE_MACHINE_TRIGGERS.feint,
  );
  const attackTrigger = useStateMachineInput(
    rive,
    STATE_MACHINE,
    STATE_MACHINE_TRIGGERS.attack,
  );
  const defenseTrigger = useStateMachineInput(
    rive,
    STATE_MACHINE,
    STATE_MACHINE_TRIGGERS.defense,
  );
  const fullattackTrigger = useStateMachineInput(
    rive,
    STATE_MACHINE,
    STATE_MACHINE_TRIGGERS.fullattack,
  );
  const counterattackTrigger = useStateMachineInput(
    rive,
    STATE_MACHINE,
    STATE_MACHINE_TRIGGERS.counterattack,
  );

  const playRamdomTrigger = useCallback(() => {
    const triggers = [
      feintTrigger,
      attackTrigger,
      defenseTrigger,
      fullattackTrigger,
      counterattackTrigger,
    ];

    const randomTrigger =
      triggers[
        Math.min(
          Math.round(Math.random() * triggers.length),
          triggers.length - 1,
        )
      ];

    randomTrigger?.fire();
  }, [
    feintTrigger,
    attackTrigger,
    defenseTrigger,
    fullattackTrigger,
    counterattackTrigger,
  ]);

  useEffect(() => {
    const intId = setInterval(() => {
      playRamdomTrigger();
    }, RANDOM_ANIMATION_INTERVAL);

    return () => {
      clearInterval(intId);
    };
  }, [playRamdomTrigger]);

  return (
    <div className="illustration" onMouseEnter={() => playRamdomTrigger()}>
      <RiveComponent />
    </div>
  );
}
