import { CgCoffee, CgDebug } from 'react-icons/cg';

import Attack from '../illustration/attack.svg';
import FullAttack from '../illustration/fullattack.svg';
import Forward from '../illustration/forward.svg';
import DbForward from '../illustration/double-forward.svg';
import Backward from '../illustration/backward.svg';
import DbBackward from '../illustration/double-backward.svg';
import Defense from '../illustration/defense.svg';
import Counter from '../illustration/counter.svg';
import Feint from '../illustration/feint.svg';
import EnGuard from '../illustration/enguard.svg';
import Null from '../illustration/null.svg';

export { default as Logo } from '../illustration/logo.svg';
export const CardIcons = {
  timeout: Null,
  move: Forward,
  moveback: Backward,
  doublemove: DbForward,
  doublemoveback: DbBackward,
  engard: EnGuard,
  fullattack: FullAttack,
  attack: Attack,
  defense: Defense,
  counterattack: Counter,
  feint: Feint,
};

export const ValueIcons = {
  COFFEE: CgCoffee,
  BUG: CgDebug,
};
