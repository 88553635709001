import React, { useEffect, useState } from 'react';
import reactDom from 'react-dom';
import './modal.scss';

const Modal = ({
  id = '',
  children,
  className = '',
  opened = true,
  onClose,
  zIndex = 11,
}) => {
  const [shouldRender, setShouldRender] = useState(opened);
  const [state, setState] = useState('leave');

  useEffect(() => {
    if (opened) {
      setState('enter');
      setShouldRender(true);
    } else {
      setState('leave');
      setTimeout(() => setShouldRender(false), 400);
    }
  }, [opened]);

  return reactDom.createPortal(
    <>
      {shouldRender ? (
        <div className="modal-wrapper" id={id}>
          <div className={`modal ${className} ${state}`} style={{ zIndex }}>
            <div className="body">
              <div className="content">{children}</div>
            </div>
            {onClose && (
              <div className="close" onClick={onClose}>
                <svg height="25" width="25" viewBox="0 0 25 25">
                  <g transform="translate(3.9661017,3.5677966)">
                    <path
                      strokeMiterlimit="4"
                      d="M -2.5783352e-4,-0.00146808 17.435473,18.212367"
                    />
                    <path
                      strokeMiterlimit="4"
                      d="M -2.5783352e-4,18.212367 17.435473,-0.00146808"
                    />
                  </g>
                </svg>
              </div>
            )}
          </div>
          <div
            className={`overlay ${state}`}
            onClick={onClose}
            style={{ zIndex: zIndex - 1 }}
          />
        </div>
      ) : null}
    </>,
    document.body,
  );
};

export default Modal;
