import React from 'react';
import './timer.scss';

function Timer({ timer, totalTime }) {
  const progress = `${Math.floor(timer * 100)}%`;
  const time = `${Math.round((totalTime - timer * totalTime) / 1000)} seg`;
  const isTimeout = timer >= 0.99;

  return (
    <div className="timer">
      {!isTimeout ? (
        <div className="progress">
          <div className="progress-bar" style={{ width: progress }} />
        </div>
      ) : null}
      <div className={`progress-percent ${isTimeout && 'progress-timeout'}`}>
        {!isTimeout ? time : 'Timeout'}
      </div>
    </div>
  );
}

export default Timer;
