import React from 'react';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas-lite';
import { useGameContext } from '../context/gameContext';
import { CardIcons } from './icons';
import './rail.scss';
import { useEffect } from 'react';

const RAIL = [0, 1, 2, 3, 4, 5, 6, 7];
const STATE_MACHINE = 'animations';
const STATE_MACHINE_TRIGGERS = {
  idle: 'idle',
  attack: 'attack',
  defense: 'defense',
  feint: 'feint',
  fullattack: 'fullattack',
  counterattack: 'counter',
};

function RailIcon({ className, icon }) {
  const { rive, RiveComponent } = useRive({
    src: '/animation.riv',
    stateMachines: STATE_MACHINE,
    autoplay: true,
  });

  const feintTrigger = useStateMachineInput(
    rive,
    STATE_MACHINE,
    STATE_MACHINE_TRIGGERS.feint,
  );
  const attackTrigger = useStateMachineInput(
    rive,
    STATE_MACHINE,
    STATE_MACHINE_TRIGGERS.attack,
  );
  const defenseTrigger = useStateMachineInput(
    rive,
    STATE_MACHINE,
    STATE_MACHINE_TRIGGERS.defense,
  );
  const fullattackTrigger = useStateMachineInput(
    rive,
    STATE_MACHINE,
    STATE_MACHINE_TRIGGERS.fullattack,
  );
  const counterattackTrigger = useStateMachineInput(
    rive,
    STATE_MACHINE,
    STATE_MACHINE_TRIGGERS.counterattack,
  );

  useEffect(() => {
    if (rive) {
      console.log('icon ====> ', icon);
      const triggers = {
        feint: feintTrigger,
        attack: attackTrigger,
        move: attackTrigger,
        doublemove: attackTrigger,
        defense: defenseTrigger,
        fullattack: fullattackTrigger,
        counterattack: counterattackTrigger,
      };

      const randomTrigger = triggers[icon];

      randomTrigger?.fire();
    }
  }, [rive, icon]);

  return (
    <div className="rail-illustration">
      <RiveComponent />
    </div>
  );
}

function Rail() {
  const {
    me,
    opponent,
    myColor,
    noMyColor,
    room: { winnerRound },
  } = useGameContext();

  const myCardKey = me?.table?.[0]?.key;
  const opponentCardKey = opponent?.table?.[0]?.key;

  const myPosition =
    myColor === 'red' ? RAIL.length - 1 - me.position : me.position;
  const noMyPosition =
    myColor === 'red' ? RAIL.length - 1 - opponent.position : opponent.position;

  return (
    <div className="rail">
      <ul className="rail-content" data-winner={winnerRound || 'none'}>
        {RAIL.map((pos, index) => (
          <li
            key={index}
            className={[
              'slot',
              myPosition === pos ? `slot-${myColor}` : '',
              noMyPosition === pos ? `slot-${noMyColor}` : '',
              winnerRound === myColor && myPosition === pos ? 'pointed' : '',
              winnerRound === noMyColor && noMyPosition === pos
                ? 'pointed'
                : '',
            ].join(' ')}
          />
        ))}
        <li
          className={`player-left player-${myColor}`}
          style={{ left: `${(100 / RAIL.length) * myPosition}%` }}
        >
          <RailIcon icon={myCardKey} alt="my icon" />
        </li>
        <li
          className={`player-right player-${noMyColor}`}
          style={{ left: `${(100 / RAIL.length) * noMyPosition}%` }}
        >
          <RailIcon icon={opponentCardKey} alt="opponent icon" />
        </li>
      </ul>
    </div>
  );
}

export default Rail;
