import React, { useEffect, useState, useRef } from 'react';
// import useCardEffect from "../hooks/useCardEffect";
import bg from '../illustration/card-texture.svg';
import Saber from '../illustration/icons/icon-saber.svg';
import Shield from '../illustration/icons/icon-shield.svg';
import CardsData from '../utils/cards';
import { CardIcons } from './icons';
import './card.scss';

const SLOT_OFFSET = 3.5;

export function Value({ type, value }) {
  const num = parseFloat(value);
  const icons = Math.abs(num);

  if (type === 'movement') {
    return null;
  }
  const Icon = type === 'attack' ? Saber : Shield;

  return (
    <div className="card-value">
      <span className="card-value-num">
        {num < 0 ? '' : ''}
        {num}
      </span>
      <span className={`card-value-icons ${num < 1 ? 'inverse' : ''}`}>
        <Icon key={`${type}`} />
      </span>
    </div>
  );
}

const getDestinyStyle = (slotId) => {
  const slotElem = document.getElementById(slotId);

  if (slotElem) {
    const slotPos = slotElem.getBoundingClientRect();

    const left = slotPos.left + SLOT_OFFSET;
    const top = slotPos.top + SLOT_OFFSET;
    const fontSize = getComputedStyle(slotElem).getPropertyValue('font-size');

    return {
      position: 'fixed',
      left,
      top,
      fontSize,
    };
  }

  return null;
};

function Card({
  id,
  type,
  selected,
  locked,
  hidden,
  bonus,
  onClick,
  color = 'green',
  slot,
  stack,
}) {
  // const cardEl = useCardEffect({ active: !locked && !hidden });
  const [position, setPosition] = useState(getDestinyStyle(slot));
  const data = CardsData[type];
  const illustration = CardIcons[type];
  const ref = useRef(null);

  const moves = <Value type="movement" value={data.movement} />;
  const attacks = <Value type="attack" value={data.attack} />;
  const defenders = <Value type="defense" value={data.defense} />;
  const attackBonus = <Value type="attack" value={data.attackBonus} />;
  const defenseBonus = <Value type="defense" value={data.defenseBonus} />;

  const rootClassName = [
    'card',
    stack ? 'stacked' : '',
    selected ? 'selected' : '',
    hidden ? 'hidden' : '',
    !stack?.length && bonus ? 'bonus' : '',
    locked ? 'locked' : '',
    'animating',
  ]
    .filter((val) => val)
    .join(' ');

  useEffect(() => {
    const reset = () => {
      const destStyle = getDestinyStyle(slot);

      if (destStyle) {
        setPosition({
          ...destStyle,
        });
      }
    };

    setPosition({
      ...getDestinyStyle(slot),
    });

    window.addEventListener('resize', reset);
    window.addEventListener('scroll', reset);

    return () => {
      window.removeEventListener('resize', reset);
      window.removeEventListener('scroll', reset);
    };
  }, [slot]); // Make sure the effect runs only once

  return (
    <div
      id={id}
      key={id}
      className={rootClassName}
      style={position}
      onClick={!hidden && !locked ? onClick : undefined}
      // ref={cardEl}
      data-slot={slot}
      ref={(d) => {
        console.log('ref', id, d === ref.current);
        ref.current = d;
      }}
    >
      {!hidden ? (
        <div className={`card-content card-${data.type}`}>
          <label>
            <h2>{data.label}</h2>
          </label>
          <div className="card-up">
            <div>{attacks}</div>
            <div>{defenders}</div>
            <div style={{ display: 'none' }}>{moves}</div>
          </div>
          <div className="card-icon">
            <img src={illustration} alt="" />
          </div>
          <div className="card-bottom">
            <div>{attackBonus}</div>
            <div>{defenseBonus}</div>
          </div>
        </div>
      ) : (
        <div className={`card-content card-${color}`}>
          <div className="card-icon card-logo">
            <img src={bg} alt="" />
          </div>
        </div>
      )}
    </div>
  );
}

export default Card;
