import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const getUserID = () => {
  let userId = localStorage.getItem('userID');

  if (!userId) {
    userId = uuidv4();
    localStorage.setItem('userID', userId);
  }

  return userId;
};

const useUserId = () => {
  const [userID] = useState(getUserID);

  return userID;
};

export default useUserId;
