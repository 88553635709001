import { useEffect, useState } from 'react';

const deepCopy = (data) => JSON.parse(JSON.stringify(data));

export const useFreeze = (data, freeze = true) => {
  const [cache, setCache] = useState(deepCopy(data));

  useEffect(() => {
    if (!freeze) {
      setCache(deepCopy(data));
    }
  }, [freeze, data]);

  return cache;
};
