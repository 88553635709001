import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import { useClipboard } from '../hooks/useClipboard';
import './loadingDialog.scss';
import Modal from './modals/modal';
import { getIaRoomUrl, getRoomUrl } from '../utils/navigation';
import { isMobile } from '../utils/is-mobile';

const COPY_TEXT = isMobile() ? 'Share link' : 'Copy link';
const OPEN_TEXT = 'Open at new tab';

function ShareButton({ url }) {
  const [copyText, setCopyText] = useState(COPY_TEXT);
  const copy = useClipboard();

  const onShare = async (event) => {
    event.preventDefault();

    if (isMobile() && navigator.share && navigator.canShare({ url })) {
      try {
        setCopyText('Sharing...');

        await navigator.share({ url, text: 'Play with me En Carte!' });
      } catch (err) {
        console.error('Error sharing', err);
      } finally {
        setCopyText('Shared');
        setTimeout(() => setCopyText(COPY_TEXT), 1000);
      }
    } else {
      copy(url);
      setCopyText('Copied');
      setTimeout(() => setCopyText(COPY_TEXT), 1000);
    }
  };

  return (
    <a
      href={url}
      disabled={!url}
      onClick={onShare}
      className="button link-copy big dark"
    >
      {copyText}
    </a>
  );
}
function LoadingDialog({ token, myColor, noMyColor, unlimited }) {
  const shareUrl = token && getRoomUrl(token, noMyColor, unlimited);
  const iaUrl = token && getIaRoomUrl('ia', myColor, unlimited);

  const base_url = window.location.origin;
  const fullUrl = `${base_url}${shareUrl}`;

  return (
    <Modal className="loadingDialog">
      <div className="row">
        <div className="column">
          <div className="row">
            <div className="column">
              <h1>Invite a friend</h1>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <div className="actions">
                <div className="row">
                  <ShareButton url={fullUrl} />
                  <a
                    href={shareUrl}
                    disabled={!shareUrl}
                    target="_blank"
                    className="button big "
                    rel="noreferrer"
                  >
                    {shareUrl ? OPEN_TEXT : 'loading'}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="qr-code">
            <QRCode value={fullUrl} fgColor="white" bgColor="#022626" />
          </div>
          <div className="row">
            <div className="alert">Waiting connection...</div>
          </div>
          <div className="row">
            <div className="column">
              <div className="actions">
                <div className="row">
                  <a
                    href={iaUrl}
                    disabled={!iaUrl}
                    className="button big"
                    rel="noreferrer"
                  >
                    Play alone
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default LoadingDialog;
