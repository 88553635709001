import { useCallback } from 'react';

export const useClipboard = () => useCallback((content) => {
  const copyText = document.createElement('input');
  document.body.appendChild(copyText);
  copyText.value = content;
  copyText.select();
  document.execCommand('copy');
  document.body.removeChild(copyText);
}, []);
