import { useEffect, useMemo, useState } from 'react';
import WebFont from 'webfontloader';

const useFontLoader = (families) => {
  const [state, setState] = useState('ready');
  const result = useMemo(
    () => ({
      isReady: state === 'ready',
      isLoading: state === 'loading',
      isLoaded: state === 'active',
    }),
    [state],
  );

  useEffect(() => {
    WebFont.load({
      google: {
        families,
      },
      loading() {
        setState('loading');
      },
      active() {
        setState('active');
      },
      inactive() {
        setState('inactive');
      },
    });
  }, [families]);

  return result;
};

export default useFontLoader;
