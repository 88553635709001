import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import About from '../components/modals/modalAbout';
import { Switch } from '../components/switch';
import Title from '../components/title';
import { Illustration } from '../components/illustration';
// import useCardEffect from '../hooks/useCardEffect'; // This import is not necessary yet
import AppLogo from '../illustration/app-logo-transparent.svg';
import { useUIContext } from '../context/uiContext';
import './home.scss';

function HomeScreen() {
  const { setShowAbout } = useUIContext();
  const history = useNavigate();

  const [unlimited, setUnlimited] = useState();
  const [color, setColor] = useState(undefined);
  const [tempColor, setTempColor] = useState('neutral');
  const [ia, setIa] = useState(true);

  const selectPlayerColor = useCallback(
    (color) => {
      history(
        `/rooms/${ia ? 'ia' : 'host'}/${color}${unlimited ? '/unlimited' : ''}`,
        { replace: false },
      );
    },
    [unlimited, ia, history],
  );

  return (
    <div className="home-screen">
      <header>
        <Title />
      </header>
      <main className="paper nopadding">
        <div className="screen home">
          <div
            className={`applogo logo ${color || tempColor} ${
              color ? 'selected' : ''
            }`}
          >
            <Illustration />
          </div>
          <div className="action-panel">
            <p>Choose a color</p>
            <div className="actions">
              <button
                className={color === 'red' ? '' : 'outline'}
                disabled={color === 'red'}
                onClick={() => setColor('red')}
                onMouseLeave={() => setTempColor('neutral')}
                onMouseEnter={() => setTempColor('red')}
              >
                Red
              </button>
              <button
                className={color === 'green' ? '' : 'outline'}
                disabled={color === 'green'}
                onClick={() => setColor('green')}
                onMouseLeave={() => setTempColor('neutral')}
                onMouseEnter={() => setTempColor('green')}
              >
                Green
              </button>
            </div>
            <div className="actions">
              <Switch
                checked={!unlimited}
                onChange={(value) => setUnlimited(!value)}
              />
              <small onClick={() => setUnlimited(!unlimited)}>time limit</small>
            </div>
            <div className="actions">
              <Switch checked={ia} onChange={(value) => setIa(value)} />
              <small onClick={() => setIa(!ia)}>against IA</small>
            </div>
            <div className="actions">
              <button
                onClick={() => selectPlayerColor(color)}
                disabled={!color}
              >
                {ia ? 'Play' : 'Create a room'}
              </button>
            </div>
            <div className="actions">
              <button onClick={() => setShowAbout(true)} className="dark">
                How to play
              </button>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <p>Online multiplayer fencing card game</p>
      </footer>
    </div>
  );
}

export default HomeScreen;
