import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';

// Init context
const Context = React.createContext({});
Context.displayName = 'IAContext';

export const useIA = () => {
  const state = useContext(Context);
  return state;
};

const createWorker = () => {
  console.log('CREATING IA');

  const worker = new Worker(new URL('./worker', import.meta.url));
  worker.onmessage = ({ data }) => console.log('IA BOT - received', data);
  worker.onerror = (error) => console.error('IA BOT - error', error);

  return worker;
};

function IAContext({ children }) {
  const receiveDataFunc = useRef();
  const receiveData = useCallback((func) => {
    receiveDataFunc.current = func;
  }, []);

  const worker = useMemo(createWorker, []);

  useEffect(() => {
    worker.onmessage = ({ data }) => receiveDataFunc.current?.(data);
  }, []);

  const send = useCallback(
    (...args) => {
      worker?.postMessage({ source: 'ia-bot', ...args[0] });
    },
    [worker],
  );

  return (
    <Context.Provider value={{ send, receiveData }}>
      {typeof children === 'function'
        ? children({
            send,
            receiveData,
          })
        : children}
    </Context.Provider>
  );
}

export default IAContext;
