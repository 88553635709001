import React from 'react';
import './switch.scss';

export function Switch({ checked, onChange }) {
  return (
    <span
      className={`switch switch-${checked ? 'on' : 'off'}`}
      onClick={() => {
        onChange(!checked);
      }}
    >
      <span className="switch-toogle" />
    </span>
  );
}
