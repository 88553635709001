import { isArray } from 'lodash';

export const shuffle = (array) => {
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const isDataAndStateEquals = (data, state) => {
  function isEqual(a, b) {
    for (const key in a) {
      if (Object.hasOwnProperty.call(a, key)) {
        const aValue = a[key];
        const bValue = b[key];

        if (typeof aValue === 'object' && typeof bValue === 'object') {
          if (!isEqual(aValue, bValue)) return false;
        } else if (aValue !== bValue) {
          return false;
        }
      }
    }
    return true;
  }

  return isEqual(data, state);
};

export const merge = (state, data) => {
  function concat(a, b) {
    if (a === undefined || a === null) return b !== undefined ? b : null;
    if (b === undefined || b === null) return a !== undefined ? a : null;

    if (typeof a === 'object') {
      const result = isArray(a) ? [] : {};

      for (const key in a) {
        if (Object.hasOwnProperty.call(a, key)) {
          const aValue = a[key];
          const bValue = b[key];

          result[key] = concat(aValue, bValue);
        }
      }
      return result;
    }
    return b;
  }

  return concat(state, data);
};

export const getOtherPlayer = (color) => (color === 'red' ? 'green' : 'red');

export const randomInt = (min = 0, max = 1) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
