// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 400px;
  margin: 20px auto 0px;
  height: 15px;
  position: absolute;
  left: 0;
  right: 0;
}
.timer .progress {
  height: 5px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-right: 10px;
}
.timer .progress .progress-bar {
  height: 5px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
.timer .progress-percent {
  width: 50px;
  font-size: 0.75em;
  white-space: nowrap;
}
.timer .progress-percent.progress-timeout {
  text-align: center;
  width: 100%;
  -webkit-animation: timeout 1.5s;
          animation: timeout 1.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
@media screen and (max-width: 720px) {
  .timer {
    margin: 0px auto 0px;
  }
}

@-webkit-keyframes timeout {
  60% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0px, 20px);
            transform: translate(0px, 20px);
    opacity: 0;
  }
}

@keyframes timeout {
  60% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0px, 20px);
            transform: translate(0px, 20px);
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/table/timer.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,yBAAA;MAAA,sBAAA;UAAA,mBAAA;EACA,gBAAA;EACA,qBAAA;EACA,YAAA;EAEA,kBAAA;EACA,OAAA;EACA,QAAA;AAAF;AAEE;EACE,WAAA;EACA,0CAAA;EACA,mBAAA;MAAA,WAAA;UAAA,OAAA;EACA,kBAAA;AAAJ;AAEI;EACE,WAAA;EACA,oCAAA;EACA,4BAAA;EAAA,oBAAA;AAAN;AAIE;EACE,WAAA;EACA,iBAAA;EACA,mBAAA;AAFJ;AAII;EACE,kBAAA;EACA,WAAA;EACA,+BAAA;UAAA,uBAAA;EACA,qCAAA;UAAA,6BAAA;AAFN;AAME;EArCF;IAsCI,oBAAA;EAHF;AACF;;AAMA;EACE;IACE,sCAAA;YAAA,8BAAA;IACA,UAAA;EAHF;EAKA;IACE,uCAAA;YAAA,+BAAA;IACA,UAAA;EAHF;AACF;;AALA;EACE;IACE,sCAAA;YAAA,8BAAA;IACA,UAAA;EAHF;EAKA;IACE,uCAAA;YAAA,+BAAA;IACA,UAAA;EAHF;AACF","sourcesContent":[".timer {\n  display: flex;\n  align-items: center;\n  max-width: 400px;\n  margin: 20px auto 0px;\n  height: 15px;\n\n  position: absolute;\n  left: 0;\n  right: 0;\n\n  .progress {\n    height: 5px;\n    border: 1px solid rgba(255, 255, 255, 0.5);\n    flex: 1;\n    margin-right: 10px;\n\n    .progress-bar {\n      height: 5px;\n      background: rgba(255, 255, 255, 0.2);\n      transition: 0.2s all;\n    }\n  }\n\n  .progress-percent {\n    width: 50px;\n    font-size: 0.75em;\n    white-space: nowrap;\n\n    &.progress-timeout {\n      text-align: center;\n      width: 100%;\n      animation: timeout 1.5s;\n      animation-fill-mode: forwards;\n    }\n  }\n\n  @media screen and (max-width: 720px) {\n    margin: 0px auto 0px;\n  }\n}\n\n@keyframes timeout {\n  60% {\n    transform: translate(0px, 0px);\n    opacity: 1;\n  }\n  100% {\n    transform: translate(0px, 20px);\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
