import React from 'react';
import Card from '../components/card';
import { CardsList, CardType } from '../utils/cards';
import './cards.scss';

export function CardsScreen() {
  const sortedList = CardsList.sort((a, b) => {
    // group by type and order by attack and defense
    if (a.type === b.type) {
      return a.type === CardType.ATTACK
        ? b.attack - a.attack || b.defense - a.defense
        : b.defense - a.defense || b.attack - a.attack;
    }

    return a.type.localeCompare(b.type);
  });

  return (
    <div>
      <div className="cards">
        {sortedList.map((card) => (
          <article className="card-info">
            <div>
              <header>
                <h2>{card.label}</h2>
                <small>
                  {card.type} - {card.count} card{card.count > 1 ? 's' : ''}
                </small>
              </header>
              <main>
                <div>
                  <span>Attack</span>
                  <span>{card.attack}</span>
                </div>
                <div>
                  <span>Defense</span>
                  <span>{card.defense}</span>
                </div>
                <div>
                  <span>Movement</span>
                  <span>{card.movement}</span>
                </div>
                <hr />
                <div>
                  <span>Attack Bonus</span>
                  <span>{card.attackBonus}</span>
                </div>
                <div>
                  <span>Defense Bonus</span>
                  <span>{card.defenseBonus}</span>
                </div>
              </main>
            </div>
            <div>
              <Card
                id={card.key}
                type={card.key}
                color="green"
                locked
                initPos={{ position: 'relative' }}
              />
            </div>
          </article>
        ))}
      </div>
    </div>
  );
}
