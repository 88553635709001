import React from 'react';
import { useGameContext } from '../../context/gameContext';

function HitLight({ color }) {
  const {
    room: { winnerRound },
  } = useGameContext();

  const hasHitted = winnerRound === color || winnerRound === 'both';

  return <div className={`hit hit-${color} ${hasHitted ? 'hitted' : ''}`} />;
}

export default HitLight;
