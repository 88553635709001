import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useGameContext } from './index';
import { HAND_CARDS, TURN_TIME } from '../../utils/statics';
import Cards from '../../utils/cards';

export const useRoom = () => {
  const { room } = useGameContext();
  return room;
};

export const usePlayer = (playerKey) => {
  const { room } = useGameContext();
  return room[playerKey] || {};
};

export const useTableCards = ({ playerKey, hideStacked = false }) => {
  const { table, cards } = usePlayer(playerKey);

  return useMemo(() => {
    const result = [];

    table.slice(0, 2).forEach((card, index) => {
      card.slot = `${playerKey}-${index}`;
      card.bonus = index === 1;
      card.locked = true;
      if (!hideStacked) {
        card.stack?.forEach((stacked, stId) => {
          stacked.slot = card.slot;
          stacked.locked = true;

          result.push(stacked);
        });
      }
      result.push(card);
    });

    return result;
  }, [table, cards, hideStacked]);
};

export const useHandCards = ({ playerKey }) => {
  const { turn, turnTotalTime } = useRoom();
  const { table, cards } = usePlayer(playerKey);

  return useMemo(() => {
    const result = [];
    const isTimeLimited = !!turnTotalTime;

    const hand = cards.slice(0, HAND_CARDS + 1);
    if (!isTimeLimited && (table.length || cards.length)) {
      result.push({
        id: `${uuidv4().replaceAll(/-/gi, '')}${turn}`,
        key: Cards.timeout.type,
        slot: `${playerKey}-2`,
        timeout: true,
      });
    }
    hand.forEach((card, index) => {
      const cardIndex = !isTimeLimited ? index + 3 : index + 2;
      card.slot = `${playerKey}-${cardIndex}`;

      card.last = index === hand.length - 1;
      result.push(card);
    });

    return result;
  }, [cards, table, turn]);
};

export const useCards = ({ renderHand, playerKey }) => {
  const table = useTableCards({ playerKey });
  const hand = useHandCards({ playerKey });

  return useMemo(() => {
    const result = [...(table || [])];

    if (renderHand) {
      result.push(...(hand || []));
    }

    return result;
  }, [hand, table, renderHand]);
};
