import React, { useEffect, useState } from 'react';
import { useGameContext } from '../../context/gameContext';
import { useTableCards } from '../../context/gameContext/hooks';
import { useFreeze } from '../../hooks/useFreeze';
import Card from '../card';
import Modal from './modal';
import ModalGameOver from './modalGameover';
import './modalResult.scss';

function ResultTable({ red, green, winnerRound }) {
  const { myColor } = useGameContext();

  const redRender = (
    <tr className={winnerRound === 'red' ? 'winner red' : 'red'}>
      <td>red</td>
      <td>{red.attack}</td>
      <td>-</td>
      <td>{green.defense}</td>
      <td>-</td>
      <td>{red.movement}</td>
      <td>=</td>
      <td>{red.power}</td>
    </tr>
  );

  const greenRender = (
    <tr className={winnerRound === 'green' ? 'winner green' : 'green'}>
      <td>green</td>
      <td>{green.attack}</td>
      <td>-</td>
      <td>{red.defense}</td>
      <td>-</td>
      <td>{green.movement}</td>
      <td>=</td>
      <td>{green.power}</td>
    </tr>
  );

  return (
    <div className="result-table">
      <table>
        <thead>
          <tr>
            <th />
            <th>attack</th>
            <th>-</th>
            <th>defense</th>
            <th>-</th>
            <th>move</th>
            <th>=</th>
            <th>power</th>
          </tr>
        </thead>
        <tbody>
          {myColor === 'red' ? redRender : greenRender}
          {myColor === 'red' ? greenRender : redRender}
        </tbody>
      </table>
    </div>
  );
}

function ModalOpponentCards() {
  const { noMyColor } = useGameContext();
  const table = useTableCards({ playerKey: noMyColor, hideStacked: true });

  return (
    <div
      className={`opponent-cards ${noMyColor}-cards`}
      style={{ fontSize: '.5em' }}
    >
      <div className="cards-mask">
        {table.map((card, index) => (
          <Card
            key={card.id}
            type={card.key}
            bonus={card.bonus}
            stack={!!card.stack?.length}
            locked
            initPos={{ position: 'relative' }}
          />
        ))}
      </div>
    </div>
  );
}

function ModalUserCards() {
  const { myColor } = useGameContext();
  const table = useTableCards({ playerKey: myColor, hideStacked: true });

  return (
    <div className={`user-cards ${myColor}-cards`} style={{ fontSize: '.5em' }}>
      <div className="cards-mask">
        {table.map((card, index) => (
          <Card
            key={card.id}
            type={card.key}
            bonus={card.bonus}
            stack={!!card.stack?.length}
            locked
            initPos={{ position: 'relative' }}
          />
        ))}
      </div>
    </div>
  );
}

function ModalResult({ opened }) {
  const [showFinal, setShowFinal] = useState(false);
  const [view, setView] = useState(false);
  const { room, setReady, myColor } = useGameContext();

  const cachedRoom = useFreeze(room, !opened);
  const { red, green, winnerRound, endedRound, winnerGame } = cachedRoom;
  const isReady = room[myColor]?.isReady;

  useEffect(() => {
    if (opened) {
      setTimeout(() => setView(true), 2500);
    } else {
      setView(false);
      setShowFinal(false);
    }
  }, [opened]);

  const closeGameOver = () => {
    setShowFinal(false);
    setView(false);
  };

  const resetRoundHandler = () => {
    // setView(false);
    setReady(myColor);
    // closeGameOver();
  };

  const actionButton = !winnerGame ? (
    <button
      className="dark big"
      disabled={!endedRound || isReady}
      onClick={resetRoundHandler}
    >
      {!isReady ? 'Next round' : 'Waiting opponent'}
    </button>
  ) : (
    <button
      className="dark big"
      disabled={!endedRound}
      onClick={() => setShowFinal(true)}
    >
      Finish game
    </button>
  );

  const pointer = winnerRound === myColor ? 'You' : 'Your opponent';
  const hasWinner = winnerRound === 'red' || winnerRound === 'green';

  return (
    <>
      <ModalGameOver opened={winnerGame && showFinal} onClose={closeGameOver} />
      <Modal id="modalResult" opened={view}>
        <ModalUserCards />
        <div className="center">
          <div className="row">
            <div className="column">
              {hasWinner && (
                <strong className={`result ${winnerRound}`}>
                  {`${pointer} pointed`}
                </strong>
              )}
              {!hasWinner && endedRound && (
                <strong className="result">None pointed</strong>
              )}
            </div>
          </div>
          <div className="row">
            <div className="column">
              <ResultTable red={red} green={green} winnerRound={winnerRound} />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <div className="actions">{actionButton}</div>
            </div>
          </div>
        </div>
        <ModalOpponentCards />
      </Modal>
    </>
  );
}

export default ModalResult;
