import React from 'react';

const UIContext = React.createContext({
  showAbout: false,
  setShowAbout: () => {},
});

export const UIContextProvider = ({ children }) => {
  const [showAbout, setShowAbout] = React.useState(false);

  return (
    <UIContext.Provider value={{ showAbout, setShowAbout }}>
      {children}
    </UIContext.Provider>
  );
};

export const useUIContext = () => {
  const state = React.useContext(UIContext);
  return state;
};
