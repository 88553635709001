// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch {
  --toogle-size: 10px;
  display: block;
  min-width: 29px;
  height: 12px;
  padding: 2px;
  border-radius: 12px;
  border: 1px solid white;
  position: relative;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.switch .switch-toogle {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  display: inline-block;
  height: var(--toogle-size);
  width: var(--toogle-size);
  border-radius: 13px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 2px;
  right: 2px;
}
.switch:hover {
  border: 1px solid rgba(255, 255, 255, 0.7);
}
.switch.switch-on {
  background: rgba(255, 255, 255, 0.2);
}
.switch.switch-on .switch-toogle {
  background-color: rgb(255, 255, 255);
  right: 2px;
}
.switch.switch-off .switch-toogle {
  background-color: rgba(255, 255, 255, 0);
  right: calc(100% - var(--toogle-size) - 4px);
}`, "",{"version":3,"sources":["webpack://./src/components/switch.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,cAAA;EACA,eAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,4BAAA;EAAA,oBAAA;AACF;AACE;EACE,4BAAA;EAAA,oBAAA;EACA,qBAAA;EACA,0BAAA;EACA,yBAAA;EACA,mBAAA;EACA,oCAAA;EACA,0CAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;AACJ;AAEE;EACE,0CAAA;AAAJ;AAGE;EACE,oCAAA;AADJ;AAGI;EACE,oCAAA;EACA,UAAA;AADN;AAKE;EACE,wCAAA;EACA,4CAAA;AAHJ","sourcesContent":[".switch {\n  --toogle-size: 10px;\n  display: block;\n  min-width: 29px;\n  height: 12px;\n  padding: 2px;\n  border-radius: 12px;\n  border: 1px solid white;\n  position: relative;\n  position: relative;\n  cursor: pointer;\n  transition: all 0.2s;\n\n  .switch-toogle {\n    transition: all 0.2s;\n    display: inline-block;\n    height: var(--toogle-size);\n    width: var(--toogle-size);\n    border-radius: 13px;\n    background-color: rgb(255 255 255);\n    border: 1px solid rgba(255, 255, 255, 0.7);\n    position: absolute;\n    top: 2px;\n    right: 2px;\n  }\n\n  &:hover {\n    border: 1px solid rgba(255, 255, 255, 0.7);\n  }\n\n  &.switch-on {\n    background: rgb(255 255 255 / 20%);\n\n    .switch-toogle {\n      background-color: rgb(255 255 255);\n      right: 2px;\n    }\n  }\n\n  &.switch-off .switch-toogle {\n    background-color: rgba(255, 255, 255, 0);\n    right: calc(100% - var(--toogle-size) - 4px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
