import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Peer from 'peerjs';

const PEERJS_CONFIG_SERVER = {
  host: '0.peerjs.com',
  secure: true,
  port: 443,
};

const MESSAGE_TYPE = {
  HANDSHAKE: 'handshake',
};

export const DEBUG_TYPE = {
  NO_LOG: 0,
  ONLY_ERRORS: 1,
  ERRORS_WARNINGS: 2,
  ALL: 3,
};

// Init context
const Context = React.createContext({});
Context.displayName = 'PeerjsContext';

export const usePeer = () => {
  const state = useContext(Context);
  return state;
};

function PeerProvider({ children, debug = DEBUG_TYPE.ALL }) {
  const [token, setToken] = useState();
  const [hostToken, setHostToken] = useState();
  const receiveDataFunc = useRef();
  const receiveData = useCallback(
    (func) => (receiveDataFunc.current = func),
    [],
  );
  const [dataConnection, setDataConnection] = useState(null);
  const [isPeerReady, setIsPeerReady] = useState(null);
  const [isPeerConnected, setIsPeerConnected] = useState(null);
  const [peer, setPeer] = useState(null);

  useEffect(() => {
    // mount a local peer
    const newPeer = new Peer(PEERJS_CONFIG_SERVER, { debug });

    // peer server answered
    newPeer.on('open', (id) => {
      setToken(newPeer.id);
      setIsPeerReady(!!id);
    });

    // another peer sends data
    newPeer.on('connection', (inConn) => {
      // con = connection external -> local

      inConn.on('data', (data) => {
        if (data?.type === MESSAGE_TYPE.HANDSHAKE) {
          setHostToken(data?.payload);
        } else {
          receiveDataFunc.current?.(data);
        }
      });
      inConn.on('close', () => {
        console.log('PeerProvider: closed');
      });
    });
    newPeer.on('close', (event) => {
      console.log('PeerProvider: close', event);
    });
    newPeer.on('disconnected', (event) => {
      console.log('PeerProvider: disconnected', event);

      // force reconnect
      newPeer.reconnect();
    });
    newPeer.on('error', (error) => {
      console.log('PeerProvider: error', error);
    });

    // closes the conection when closing the tab/window to notify the peers
    window.addEventListener('unload', () => {
      newPeer?.destroy?.();
    });

    setPeer(newPeer);

    return () => {
      if (newPeer) {
        newPeer.destroy?.();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if there is a valid external peer id, connect to it
    const isNotHost = !!hostToken && hostToken !== 'host';

    if (isPeerReady) {
      if (peer && isNotHost) {
        // outConn = connection local -> external
        const outConn = peer.connect(hostToken);

        if (!outConn) {
          setIsPeerConnected(false);
          return;
        }

        // external peer answered connection request
        outConn.on('open', () => {
          outConn?.send({
            type: MESSAGE_TYPE.HANDSHAKE,
            payload: peer.id,
          });
          setIsPeerConnected(true);
        });

        // external peer closed
        outConn.on('close', () => {
          console.log('PeerProvider: closing');
          setIsPeerConnected(false);
        });

        outConn.on('error', (error) => {
          console.error(error);
          setIsPeerConnected(false);
        });

        setDataConnection(outConn);

        // notify external peer that the local is closing tab/window
        window.addEventListener('unload', () => {
          outConn.close();
        });

        return () => outConn.close();
      }
    }
  }, [peer, hostToken, isPeerReady]);

  const send = useMemo(
    () =>
      isPeerConnected && dataConnection?.open && dataConnection?.send
        ? (data) => dataConnection.send(data)
        : (...args) => console.log('Sending fallback func', ...args),
    [isPeerConnected, dataConnection],
  );

  return (
    <Context.Provider
      value={{
        send,
        token,
        setToken,
        setHostToken,
        receiveData,
        isConnected: isPeerReady && isPeerConnected,
      }}
    >
      {typeof children === 'function'
        ? children({
            send,
            token,
            setToken,
            setHostToken,
            receiveData,
            isConnected: isPeerReady && isPeerConnected,
          })
        : children}
    </Context.Provider>
  );
}

export default PeerProvider;
