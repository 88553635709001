import React from 'react';
import { useGameContext } from '../context/gameContext';
import ModalStart from './modals/modalStart';

function Game({ children }) {
  const { room } = useGameContext();

  const { red, green } = room;
  const isReady = red.isReady && green.isReady;

  return (
    <>
      <ModalStart opened={!isReady && room.turn === 0} />
      <div id="game">{children}</div>
    </>
  );
}

export default Game;
