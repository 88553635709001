import React, { useCallback } from 'react';
import { useUIContext } from '../../context/uiContext';
import Cards, { cardsCount, CardsList, CardType } from '../../utils/cards';
import Card from '../card';
import Title from '../title';
import Modal from './modal';
import './modalAbout.scss';

const CardsKeys = CardsList.sort((a, b) => {
  // attack cards first
  if (a.type === CardType.ATTACK && b.type !== CardType.ATTACK) return -1;
}).map((card) => card.key);

function getNextCardKey(key, inverse = false) {
  const index = CardsKeys.indexOf(key);
  const nextIndex = inverse ? index - 1 : index + 1;

  return CardsKeys[
    nextIndex < 0 ? CardsKeys.length - 1 : nextIndex % CardsKeys.length
  ];
}

function AboutCard({ id }) {
  const [cardKey, setCardKey] = React.useState(id);

  const cardInfo = Cards[cardKey];
  const currentCardIndex = CardsKeys.indexOf(cardKey);

  if (!cardInfo) return null;

  return (
    <>
      <div className="block card-block">
        <div className="sub-block arrow top">
          <h4>On current turn</h4>
          <p>{cardInfo.attack} attack points</p>
          <p>{cardInfo.defense} defense points</p>
        </div>
        <Card
          id="example"
          type={cardKey}
          color="green"
          locked
          initPos={{ position: 'relative' }}
        />
        <div className="sub-block arrow bottom">
          <h4>On next turn</h4>
          <p>{cardInfo.attackBonus} attack point</p>
          <p>{cardInfo.defenseBonus} defense point</p>
        </div>
        <nav className="card-nav">
          <button
            className="btn previous"
            onClick={() => setCardKey(getNextCardKey(cardKey, true))}
          >
            Previous
          </button>
          {Object.keys(Cards).map((key, index) => (
            <span
              className={index === currentCardIndex ? 'item current' : 'item'}
            >
              •
            </span>
          ))}
          <button
            className="btn next"
            onClick={() => setCardKey(getNextCardKey(cardKey))}
          >
            Next
          </button>
        </nav>
      </div>
    </>
  );
}

function About({ onClose }) {
  const { showAbout, setShowAbout } = useUIContext();

  const onCloseHandler = useCallback(() => {
    setShowAbout(false);
    onClose?.();
  }, [onClose]);

  return (
    <Modal className="about-modal" opened={showAbout} onClose={onCloseHandler}>
      <div className="row">
        <div className="column">
          <Title />
          <p>
            <strong>Created by</strong> André Pessoa and Cris Rocha
            <br />
            <strong>Developed by</strong> André Pessoa
          </p>
          <p className="lead">
            <strong>Summary:</strong> Two opposing players have 10 seconds to
            choose one of the five pre-drawn cards from their decks and reveal
            them simultaneously. They must then compare the attack, defense, and
            movement values between these cards and the ones chosen in the
            previous round to determine if there's a winner in this round or if
            they should play another round. The first player to score 3 points
            wins.
          </p>
          <h2>Overview</h2>
          <p>
            The game is a duel between two fencers on the playing field,
            represented by two figures facing each other on an 8-space track.
          </p>
          <p>
            Each player has a deck containing
            <strong> {cardsCount() + 2} cards</strong>, each with attack and
            defense points, as well as forward and backward movement.
          </p>
          <p>
            In each turn, both players have 10 seconds to choose one of the
            cards in their hand and play them simultaneously to resolve the
            conflict. If contact occurs, the winning player scores a point, and
            the round restarts until the first player scores 3 points and wins
            the match.
          </p>
          <h2>Turn</h2>
          <p>
            At the beginning of the game, each player must draw cards from their
            deck until they have 6 cards in hand. Turns continue until one of
            the conflicts results in a touch.
          </p>
          <p>
            In each turn, both players must choose one of the cards in their
            hand, or a joker, within 10 seconds. When both have chosen a card,
            they must be revealed simultaneously, and the result is resolved by
            following these steps:
          </p>
          <ol className="block">
            <li>
              Move both pieces:
              <ol>
                <li>
                  If one of the players moves off the track, the turn ends, and
                  1 point is awarded to the other player.
                </li>
                <li>
                  If the players pass through the same square, both will suffer
                  a penalty in the conflict resolution in this turn equal to the
                  number of spaces they moved, and it should be considered a
                  touch even if neither made an attack.
                </li>
              </ol>
            </li>
            <li>
              Perform attack:
              <ol>
                <li>
                  Check if there was contact: at least one of the players
                  entered the other's square or is in the square in front of the
                  other and used an attack card.
                </li>
                <li>
                  If contact occurred, the attack strength of the chosen card
                  and the attack bonus of the card chosen in the previous turn
                  for each player must be added. This will be each player's
                  attack strength.
                </li>
                <li>
                  Then the defense strength of the chosen card and the defense
                  bonus of the card chosen in the previous turn for each player
                  must be added. This will be their defense strength.
                </li>
                <li>
                  The conflict result for each player will be their attack
                  strength minus the other's defense and any movement penalties,
                  if any.
                </li>
                <li>
                  The player with the highest value wins the turn and scores a
                  point.
                </li>
                <li>
                  If contact occurred, even in case of a tie, the turn must be
                  finished, and the pieces moved to their starting positions.
                </li>
              </ol>
            </li>
            <li>
              End of the game:
              <ol>
                <li>One of the players scores 3 points, winning the game.</li>
                <li>
                  There are no more cards in the decks, and the player with the
                  most points wins.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <h2>The Card</h2>
          <p>
            Each card shows the values to be used in the current turn at the top
            and those to be used as bonuses in the next turn at the bottom.
          </p>
          <AboutCard id="attack" />
        </div>
      </div>
      <div className="row">
        <div className="column">
          <h2>"Blank" Play</h2>
          <p>
            If the player does not choose a card in time, they must play a "keep
            distance" card and discard a random card from their hand.
          </p>
          <p>
            This card must be resolved after movement and attacks are performed,
            so it does not influence the outcome of this turn.
          </p>
          <p className="block">
            You must move the figure 1 space to maintain a 1-square distance
            from the opponent, but without leaving the track. Move backward if
            they are adjacent or forward if they are 2 or more spaces apart.
          </p>
          <p>
            This card does not give attack or defense bonuses in the next turn.
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default About;
