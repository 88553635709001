import React, { useCallback, useEffect, useRef } from 'react';
import reactDom from 'react-dom';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { useGameContext } from '../context/gameContext';

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 111,
};

const Fireworks = () => {
  const refAnimationInstance = useRef(null);
  const { winnerGame } = useGameContext();

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(
    (winnerGame) => {
      const pastelReds = [
        '#C01C1C',
        '#D64545',
        '#E26A6A',
        '#EDA1A1',
        '#F4C5C5',
        '#F9E1E1',
        '#FDF2F2',
      ];

      const pastelGreens = [
        '#1E7033',
        '#3F8E52',
        '#61AD72',
        '#81C797',
        '#A4E0B5',
        '#C8EDD3',
        '#EBF8ED',
      ];

      const colors = winnerGame === 'green' ? pastelGreens : pastelReds;

      makeShot(0.25, {
        spread: 26,
        startVelocity: 55,
        colors,
      });

      makeShot(0.2, {
        spread: 60,
        colors,
      });

      makeShot(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        colors,
      });

      makeShot(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        colors,
      });

      makeShot(0.1, {
        spread: 120,
        startVelocity: 45,
        colors,
      });
    },
    [makeShot],
  );

  useEffect(() => {
    fire(winnerGame);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return reactDom.createPortal(
    <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />,
    document.body,
  );
};

export default Fireworks;
